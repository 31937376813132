import React from "react";
import GoogleMapReact from "google-map-react";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout";

const links = ["アクセス", "地図"];

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const AccessPage = props => {
  return (
    <>
      <Layout
        links={links}
        title="Access"
        subTitle="アクセス"
        seoTitle="アクセス"
        seoKeywords="狭山スイミング,マップ,地図,狭山市プール,埼玉プール,所沢プール"
        seoDescrption="狭山スイミングクラブ アクセスページ"
      >
        <Container>
          <div
            style={{
              height: "80vh",
              width: "90%",
              "text-align": "center",
              margin: "20px",
            }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA9vp-0pumZ7V0keNxTC3fy83As9bPGOsc",
              }}
              defaultCenter={{ lat: 35.847632, lng: 139.428988 }}
              defaultZoom={17}
              draggable={false}
              scaleControl={true}
              navigationControl={false}
              mapTypeControl={false}
              scrollwheel={false}
              disableDefaultUI={true}
              mapTypeId="ROADMAP"
            >
              <AnyReactComponent lat={35.847632} lng={139.428988} text="SSC" />
            </GoogleMapReact>
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default AccessPage;
